<template>
  <div class="divBox">
    <div class="selCard">
      <el-form
        inline
        size="small"
        :model="tableFrom"
        ref="searchForm"
        label-width="120px"
      >
        <el-form-item label="商品名称：" prop="goodsName">
          <el-input
            class="selWidth"
            v-model="tableFrom.goodsName"
            placeholder="请输入商品名称"
          ></el-input>
        </el-form-item>

        <el-form-item label="所属品牌" prop="brandId">
          <el-select
            v-model="tableFrom.brandId"
            clearable
            placeholder="请选择"
            class="selWidth"
          >
            <el-option
              v-for="(item, index) in brandList"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" size="small" @click="search()"
            >搜索</el-button
          >
          <el-button size="small" @click="searchReset()">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-card class="mt14">
      <el-button @click="add" size="mini" type="primary">新增</el-button>
      <el-button @click="handleDelete(selectionIds)" size="mini" type="primary"
        >批量删除</el-button
      >
      <el-button @click="setMustBuy(selectionIds, 2)" size="mini" type="primary"
        >设置限购</el-button
      >
      <el-button @click="setMustBuy(selectionIds, 1)" size="mini" type="primary"
        >设置必买</el-button
      >
      <el-button size="mini" type="primary" class="mb10" @click="importFun"
        >导入</el-button
      >
      <el-button size="mini" type="primary" class="mb10" @click="exportFun"
        >导出</el-button
      >
      <el-button size="mini" type="primary" class="mb10" @click="batchDelGoods"
        >批量移除商品</el-button
      >

      <el-table
        style="margin-top: 10px"
        v-loading="listLoading"
        :data="tableData.data"
        size="small"
        highlight-current-row
        @selection-change="selectionChange"
      >
        <el-table-column key="2" type="selection" width="55" />
        <!--  <el-table-column
          type="index"
          :index="getTabelIndex" 
          width="100"
          label="序号"
        /> -->
        <el-table-column label="商品图" min-width="70">
          <template slot-scope="scope">
            <div class="demo-image__preview">
              <el-image
                style="width: 36px; height: 36px"
                :src="scope.row.mainImg"
                :preview-src-list="[scope.row.mainImg]"
              />
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="brandName" label="品牌名称" min-width="150" />
        <el-table-column prop="name" label="商品名称" min-width="200">
          <template slot-scope="scope">
            <div>
              <span
                class="tags_name"
                :style="
                  scope.row.specType == 0
                    ? 'color: #ff8a4d;'
                    : 'color: #4073FA;'
                "
                :class="'name' + scope.row.spec_type"
                >{{ scope.row.specType == 0 ? "[单规格]" : "[多规格]" }}</span
              >{{ scope.row.name || "-" }}
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="classifyNames" label="分类名称" min-width="200">
          <template slot-scope="scope">
            <div v-if="scope.row.firstCategoryName">
              {{
                `${scope.row.firstCategoryName}/${scope.row.secondCategoryName}`
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="classifyNames"
          label="分组名称"
          min-width="150"
        />
        <el-table-column label="限购" min-width="150">
          <template slot-scope="scope">
            <div
              class="demo-image__preview"
              v-if="
                scope.row.limitBuyLeastDeptNames ||
                scope.row.limitBuyLeastSexName
              "
            >
              <div>
                {{ `限制性别名称:${scope.row.mustBuyLeastSexName || "-"}` }}
              </div>
              <div>
                {{ `限制部门名称:${scope.row.limitBuyLeastDeptNames || "-"}` }}
              </div>
            </div>
            <div v-else>-</div>
          </template>
        </el-table-column>
        <el-table-column label="必买" min-width="150">
          <template slot-scope="scope">
            <div
              class="demo-image__preview"
              v-if="
                scope.row.limitBuyLeastDeptNames ||
                scope.row.limitBuyLeastSexName
              "
            >
              <div>
                {{ `必买性别名称:${scope.row.mustBuyLeastSexName || "-"}` }}
              </div>
              <div>
                {{ `必买部门名称:${scope.row.mustBuyLeastDeptNames || "-"}` }}
              </div>
            </div>
            <div v-else>-</div>
          </template>
        </el-table-column>
        <el-table-column prop="E3" label="是否为E3" min-width="90">
          <template slot-scope="scope">
            <div>{{ scope.row.e3 ? "是" : "否" }}</div>
          </template>
        </el-table-column>

        <el-table-column prop="normalPrice" label="商品售价(元)" min-width="90" />
        <el-table-column prop="discountPrice" label="商品活动价(元)" min-width="90" />
        <el-table-column prop="actualSaleNum" label="活动销量" min-width="70" />
        <!-- <el-table-column prop="stock" label="库存" min-width="70" /> -->
        <el-table-column prop="sort" label="排序" min-width="60" />
        <el-table-column prop="status" label="商品状态" min-width="80">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.status"
              :active-value="1"
              :inactive-value="0"
              @change="onchangeStatus(scope.row)"
            />
          </template>
        </el-table-column>

        <el-table-column label="操作" min-width="320" fixed="right">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              icon="el-icon-edit"
              @click="setMustBuy([scope.row.id], 2)"
              >设置限购</el-button
            >
            <el-button
              type="text"
              size="small"
              icon="el-icon-edit"
              @click="setMustBuy([scope.row.id], 1)"
              >设置必买</el-button
            >
            <el-button
              type="text"
              size="small"
              icon="el-icon-edit"
              @click="onEdit(scope.row)"
              >编辑</el-button
            >
            <el-button
              type="text"
              size="small"
              icon="el-icon-delete"
              @click="handleDelete([scope.row.id])"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          background
          :page-size="tableFrom.pageSize"
          :current-page="tableFrom.pageNum"
          layout="total, prev, pager, next, jumper"
          :total="tableData.total"
          @size-change="handleSizeChange"
          @current-change="pageChange"
        />
      </div>
    </el-card>
    <!-- 必买 -->
    <el-dialog
      :title="type == 2 ? '设置限购' : '设置必买'"
      :visible.sync="dialogVisible"
      width="700px"
      :before-close="handleClose"
    >
      <el-form
        :model="form"
        ref="form"
        label-width="150px"
        class="demo-ruleForm"
      >
        <!-- 必买  -->
        <div v-if="type == 1">
          <el-form-item label="部门:" prop="mustBuyLeastDept">
            <el-cascader
              v-model="form.mustBuyLeastDept"
              :options="deptList"
              :props="{ multiple: true, label: 'name', value: 'id' }"
              clearable
            ></el-cascader>
          </el-form-item>

          <el-form-item label="性别:" prop="mustBuyLeastSex">
            <el-radio-group v-model="form.mustBuyLeastSex">
              <el-radio :label="1">男</el-radio>
              <el-radio :label="2">女</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <!-- 限购  -->
        <div v-if="type == 2">
          <el-form-item label="部门:" prop="limitBuyLeastDept">
            <el-cascader
              v-model="form.limitBuyLeastDept"
              :options="deptList"
              :props="{ multiple: true, label: 'name', value: 'id' }"
              clearable
            ></el-cascader>
          </el-form-item>

          <el-form-item label="性别:" prop="limitBuyLeastSex">
            <el-radio-group v-model="form.limitBuyLeastSex">
              <el-radio :label="1">男</el-radio>
              <el-radio :label="2">女</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="最低限购数量:"
            prop="limitBuyLeastNumMin"
            v-if="type == 2"
          >
            <el-input
              v-model="form.limitBuyLeastNumMin"
              placeholder="请输入"
            ></el-input>
          </el-form-item>

          <el-form-item
            label="最高限购数量:"
            prop="limitBuyLeastNumMax"
            v-if="type == 2"
          >
            <el-input
              v-model="form.limitBuyLeastNumMax"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
        </div>

        <el-form-item>
          <el-button
            type="primary"
            :loading="vLoading"
            @click="submitForm('form')"
            >提交</el-button
          >
          <!-- <el-button @click="resetForm('form')">重置</el-button> -->
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog
      title="选择商品"
      :visible.sync="dialogGoodsVisible"
      width="1000px"
    >
      <ChangeStoreGoods @getGoodsItem="getGoodsItem" />
    </el-dialog>

    <Import
      v-if="importShow"
      :show="importShow"
      :action="importAction"
      :downloadLink="importDownloadLink"
      :problemLink="importProblem"
      :params="{ activityId: tableFrom.activityId }"
      @close="importClose"
    />
  </div>
</template>
<script>
import {
  activityGoodsList,
  activityGoodsAdd,
  activityGoodsUp,
  activityGoodsDel,
  activityGoodsState,
  activityGoodsMustBuy,
  activityGoodsLimitBuy,
  // activityDel,
  // activityUp,
} from "@/api/market/activity";
import { goodsDeptTree, goodBrandTotalListAll } from "@/api/goods/goods";
import FormImgUpload from "@/components/formComponents/formImgUpload";
import ChangeStoreGoods from "./changeStoreGoods";
import { getIndex } from "@/utils/other";
import Import from "@/components/import/import.vue";
import { exportExcelPost } from "@/assets/utils/exportExcel";
export default {
  name: "activityGoods",
  components: {
    FormImgUpload,
    ChangeStoreGoods,
    Import,
  },
  data() {
    return {
      importShow: false,
      importAction: "",
      importDownloadLink: "",
      importProblem: "",
      dialogGoodsVisible: false,
      type: 1, //1是必买 2是限购
      vLoading: false,
      selectionIds: [],
      deptList: [],
      brandList: [], //品牌列表
      activityIds: [],
      title: "新增活动",
      tableFrom: {
        pageNum: 1,
        pageSize: 20,
        activityId: "",
      },
      form: {
        mustBuyLeastDept: [],
        mustBuyLeastSex: "",
        limitBuyLeastDept: [],
        limitBuyLeastSex: "",
        limitBuyLeastNumMin: "",
        limitBuyLeastNumMax: "",
      },
      listLoading: false,
      tableData: {
        data: [{}],
        total: 0,
      },
      dialogVisible: false,
      dialogVisibleLink: false,
      // rules: {
      //   mustBuyLeastDept: [
      //     { required: true, message: "请选择必买部门", trigger: "blur" },
      //   ],
      //   mustBuyLeastSex: [
      //     { required: true, message: "请选择必买性别", trigger: "blur" },
      //   ],
      // },
    };
  },
  mounted() {
    console.log("123---->", this.$route.query.id);
    this.tableFrom.activityId = this.$route.query.id;
    this.getList();
    this.getDept();
    this.getBrandList();
  },
  methods: {
    search() {
      this.tableFrom.pageNum = 1;
      this.getList();
    },
    searchReset() {
      this.$refs.searchForm.resetFields();
      this.getList(1);
    },
    getBrandList() {
      goodBrandTotalListAll(1).then((res) => {
        this.brandList = res.data;
      });
    },
    batchDelGoods(){
      this.importAction = "/api/goods/web/activity/goodsList/delete/import";
      this.importDownloadLink = "./活动待移除商品导入模板.xlsx";
      this.importProblem = "/api/goods/web/activity/delete/error/export";
      this.importShow = true;
    },
    importFun() {
      this.importAction = "/api/goods/web/activity/importGoods";
      this.importDownloadLink = "/api/goods/web/activity/template/export";
      this.importProblem = "/api/goods/web/activity/error/export";
      this.importShow = true;
    },
    exportFun(){
      exportExcelPost("/goods/web/activity/goodsList/export", {
        ...this.tableFrom,
        pageNum: 1,
        responseType: "blob",
        exportExcelName: "活动商品列表",
      });
    },
    // 导入弹框关闭
    importClose(e) {
      this.importShow = false;
      if (e === 1 || e === 2) {
        this.getList();
      }
    },
    async getDept() {
      const { data } = await goodsDeptTree();
      this.deptList = data;
    },
    uploadDelete() {},
    uploadBefore(file, done, loading, column) {
      if (file.type === "" || column.accept.indexOf(file.type) < 0) {
        this.$message.error("文件格式有误");
        loading();
      } else {
        done();
      }
    },
    uploadError(error, column) {
      this.$message.error(error);
    },
    uploadAfter(res, done) {
      done();
    },
    upload(e) {
      console.log("123", e);
      if (Array.isArray(e)) {
        this.form.primaryPictureList = [...e];
        this.$forceUpdate();
      } else {
        this.form.primaryPictureList = [...this.form.primaryPictureList, e];
        this.$forceUpdate();
      }
    },
    uploadBanner(e) {
      if (Array.isArray(e)) {
        this.form.bannerList = [...e];
        this.$forceUpdate();
      } else {
        this.form.bannerList = [...this.form.bannerList, e];
        this.$forceUpdate();
      }
    },
    getTabelIndex(index) {
      return getIndex(index, this.tableFrom.pageNum, this.tableFrom.pageSize);
    },

    selectionChange(val, row) {
      //单选Checkbox的事件
      let ids = val.map((ele) => ele.id);
      this.selectionIds = ids;
    },
    setMustBuy(delIds, type) {
      if (delIds.length == 0) {
        this.$message.error("请选择商品");
        return;
      }
      this.type = type;
      this.dialogVisible = true;
      this.activityIds = delIds;
    },

    getList() {
      this.listLoading = true;
      activityGoodsList(this.tableFrom)
        .then((res) => {
          this.tableData.data = res.data.records;
          this.tableData.total = res.data.total;
          this.listLoading = false;
        })
        .catch((res) => {
          this.listLoading = false;
        });
    },
    handleDelete(delIds) {
      if (delIds.length == 0) {
        this.$message.error("请选择商品");
        return;
      }
      this.$confirm("是否删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        console.log("delIds", delIds);
        activityGoodsDel({ ids: delIds }).then((res) => {
          if (res.code === 200) {
            this.$message.success("删除成功");
            this.selectionIds = [];
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
    getGoodsItem(e) {
      console.log("e---", e);
      this.$router.push({
        path: "/market/create",
        query: {
          goodsId: e.goodsId,
          storeGoodsId: e.id,
          operationType: "add",
          activityId: this.tableFrom.activityId,
        },
      });
    },

    onchangeIsShow(row) {
      activityStateUp({ id: row.id, state: row.state })
        .then(({ msg }) => {
          this.getList();
          this.$message.success(msg);
        })
        .catch(({ msg }) => {
          this.$message.error(msg);
          this.getList();
        });
    },
    pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    storeChange() {
      this.form.aboutId = "";
      this.form.aboutName = "";
    },
    // getGoodsItem(row) {
    //   this.form.aboutId = row.id;
    //   this.form.aboutName = row.name;
    //   this.dialogVisibleLink = false;
    // },
    formSubmit() {},
    add() {
      this.title = "新增活动";
      this.dialogGoodsVisible = true;
    },
    // 编辑
    onEdit(e) {
      // this.$router.push({
      //   path: "/storeGoods/create",
      //   query: {
      //     goodsId: row.goodsId,
      //     id: row.id,
      //   },
      // });
      this.$router.push({
        path: "/market/create",
        query: {
          storeGoodsId: e.storeGoodsId,
          id: e.id,
          operationType: "edit",
          activityId: this.tableFrom.activityId,
        },
      });
    },
    handleClose() {
      this.$refs.form.resetFields();
      this.form = {
        mustBuyLeastDept: [],
        mustBuyLeastSex: "",
        limitBuyLeastDept: [],
        limitBuyLeastSex: "",
        limitBuyLeastNumMin: "",
        limitBuyLeastNumMax: "",
      };
      this.dialogVisible = false;
    },
    handleCloseLink() {
      this.dialogVisibleLink = false;
    },
    onchangeStatus(row) {
      activityGoodsState({ id: row.id, status: row.status })
        .then(({ msg }) => {
          this.getList();
        })
        .catch(({ msg }) => {
          this.getList();
        });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.type == 1) {
            this.vLoading = true;
            activityGoodsMustBuy({
              mustBuyLeastDept: this.form.mustBuyLeastDept,
              ids: this.activityIds,
              mustBuyLeastSex: this.form.mustBuyLeastSex,
              mustBuyStatus:
                this.form.mustBuyLeastDept.length || this.form.mustBuyLeastSex
                  ? 1
                  : 0,
            })
              .then((res) => {
                this.vLoading = false;
                if (res.code === 200) {
                  this.$message.success("修改成功");
                  this.getList();
                  this.handleClose();
                  this.selectionIds = [];
                  this.activityIds = [];
                  // done();
                } else {
                  // done();
                }
              })
              .catch((err) => {
                this.vLoading = false;
              });
          } else {
            this.vLoading = true;
            activityGoodsLimitBuy({
              ids: this.activityIds,
              limitBuyLeastDept: this.form.limitBuyLeastDept,
              limitBuyLeastNumMax: this.form.limitBuyLeastNumMax,
              limitBuyLeastNumMin: this.form.limitBuyLeastNumMin,
              limitBuyLeastSex: this.form.limitBuyLeastSex,
              limitBuyStatus:
                this.form.limitBuyLeastDept.length ||
                this.form.limitBuyLeastSex ||
                this.form.limitBuyLeastNumMin ||
                this.form.limitBuyLeastNumMax
                  ? 1
                  : 0,
            })
              .then((res) => {
                this.vLoading = false;
                if (res.code === 200) {
                  this.$message.success("修改成功");
                  this.getList();
                  this.handleClose();
                  this.selectionIds = [];
                  this.activityIds = [];
                  // done();
                } else {
                  // done();
                }
              })
              .catch((err) => {
                this.vLoading = false;
              });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>
  
<style lang="scss" scoped>
.changeLink {
  width: 200px;
  height: 30px;
  border: 1px solid #dcdfe6;
  line-height: 30px;
  font-size: 14px;
  padding-left: 14px;
  box-sizing: border-box;
  cursor: pointer;
}
.inputWidth {
  width: 300px;
}
.inputBox {
  width: 100%;
  display: flex;
  align-items: center;
}
.colorBox {
  width: 34px;
  height: 34px;
}
</style>
  